<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

// import Swal from "sweetalert2";
// import axios from "axios";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Tambah Breakdown",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Tambah Breakdown",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Breakdown",
          href: "/master/Breakdown",
        },
        {
          text: "Tambah Breakdown",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,
      // variable Page
      master_role: ["one", "two", "three"],
      role_selected: "",
    };
  },
  mounted() {},
  methods: {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <b-form class="p-2">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <b-form-group
                    class="mb-3"
                    label="keterangan"
                    label-for="formrow-keterangan-input"
                  >
                    <b-form-input
                      id="formrow-keterangan-input"
                      type="text"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    class="mb-3"
                    label="Breakdown Biaya Sponsorship"
                    label-for="formrow-Breakdown-b_sponsorship-input"
                  >
                    <b-form-input
                      id="formrow-Breakdown-b_sponsorship-input"
                      type="text"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    class="mb-3"
                    label="Periode Awal"
                    label-for="formrow-periode_awal-input"
                  >
                    <b-form-input
                      id="formrow-periode_awal-input"
                      type="date"
                      class="form-control"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    class="mb-3"
                    label="Periode Akhir"
                    label-for="formrow-periode_akhir-input"
                  >
                    <b-form-input
                      id="formrow-periode_akhir-input"
                      type="date"
                      class="form-control"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <b-button type="submit" variant="primary" class="m-1"
                ><i class="fa fa-save"></i> Simpan</b-button
              >
              <b-button type="reset" variant="danger" class="m-1"
                ><i class="fa fa-refresh"></i> Reset</b-button
              >
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
